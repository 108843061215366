import React from "react";

import {
  StatusCancelledIcon,
  StatusCompleteIcon,
  StatusFinalIcon,
  StatusInProgressIcon,
  StatusNewIcon,
  StatusReceivedIcon,
} from "@blis/uiv2/icons";

import { Badge, Flex } from "../base";
import { cn } from "../utils";
import { badgeStyles, statusMap } from "./styles";

type Status =
  | "NEW"
  | "RECEIVED"
  | "IN_PROGRESS"
  | "COMPLETED"
  | "REPORTED"
  | "CANCELLED"
  | "new"
  | "received"
  | "in_progress"
  | "completed"
  | "reported"
  | "cancelled"
  | "final";

const StatusReportedIcon = StatusFinalIcon;

const IconMap: Record<Status, typeof StatusNewIcon> = {
  NEW: StatusNewIcon,
  RECEIVED: StatusReceivedIcon,
  IN_PROGRESS: StatusInProgressIcon,
  COMPLETED: StatusCompleteIcon,
  REPORTED: StatusReportedIcon,
  CANCELLED: StatusCancelledIcon,
  new: StatusNewIcon,
  received: StatusReceivedIcon,
  in_progress: StatusInProgressIcon,
  completed: StatusCompleteIcon,
  reported: StatusReportedIcon,
  cancelled: StatusCancelledIcon,
  final: StatusFinalIcon,
};

export const StatusIcon = React.forwardRef<
  React.ElementRef<(typeof IconMap)[Status]>,
  React.ComponentPropsWithoutRef<(typeof IconMap)[Status]> & {
    status: Status;
    noColor?: boolean;
  }
>(({ className, status, noColor, color, ...props }, ref) => {
  const Icon = IconMap[status];
  const iconColor = color ?? statusMap[status];
  return (
    <Icon
      ref={ref}
      {...props}
      color={noColor ? undefined : iconColor}
      className={cn("size-4", className)}
    />
  );
});

export function StatusBadge({ status }: { status: Status }) {
  const Icon = IconMap[status];
  const color = statusMap[status];
  return (
    <Badge className={cn(badgeStyles({ color }), "overflow-hidden")}>
      <Flex align="center" gap="1" className="flex-nowrap whitespace-nowrap">
        <Icon className={cn(badgeStyles({ color }))} />
        <span style={{ textTransform: "capitalize" }}>
          {status.toLowerCase().replace("_", " ")}
        </span>
      </Flex>
    </Badge>
  );
}
