import React from "react";
import { twMerge } from "tailwind-merge";

import type { PolymorphicComponentPropsWithRef } from "../utils/polymorphic";

type LinkProps<As extends React.ElementType> =
  PolymorphicComponentPropsWithRef<As>;

type LinkComponent = <As extends React.ElementType = "a">(
  props: LinkProps<As>,
) => React.ReactElement | null;

const Link = React.forwardRef<
  React.ElementRef<"a">,
  PolymorphicComponentPropsWithRef<"a">
>(({ as, className, ...props }, ref) => {
  const Comp = as || "a";
  return (
    <Comp
      {...props}
      ref={ref}
      className={twMerge(
        "text-primary hover:underline underline-offset-4 rounded-sm cursor-pointer",
        "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none",
        className,
      )}
    />
  );
}) as LinkComponent;

export { Link };
