import type { ReactNode } from "react";

import { useConfig } from "@/lib/config";
import { Badge, Centered, Flex, Text } from "@blis/ui";

export function AuthLayout({
  children,
  title,
}: {
  children: ReactNode;
  title: string;
}) {
  const config = useConfig();
  return (
    <Centered className="bg-gradient-to-br from-white to-slate-100">
      <Flex
        direction="column"
        align="center"
        className="relative bg-background border rounded-md shadow-xl p-8 w-[400px] mx-5 sm:mx-0"
      >
        <Flex justify="center" className="w-full">
          <img
            src={config.logoUrl}
            style={{ objectFit: "contain", maxWidth: "100%", maxHeight: 40 }}
            alt="logo"
          />
        </Flex>
        <Flex align="center" className="mt-5 gap-2">
          <Text size="6" bold>
            {title}
          </Text>
          {config.testEnv && <Badge>Test</Badge>}
        </Flex>
        {children}
      </Flex>
    </Centered>
  );
}
