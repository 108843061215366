import {
  type HTMLAttributes,
  forwardRef,
  useEffect,
  useMemo,
  useState,
} from "react";

import { Centered, Skeleton, Text } from "../../base";
import { useKeyNav } from "../../hooks";
import { cn } from "../../utils";

import { ListItem, ListItemLeft, ListItemRight } from "./ListItem";

export const List = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  (props, ref) => <div {...props} ref={ref} />,
);

export type ListViewProps<T extends object, G extends keyof T> = {
  items: T[];
  onItemSelected?: (item: T | null) => void;
  initialSelected?: T | null;
  onRender: (item: T, selected: T | null) => React.ReactNode;
  groupByFn?: (item: T) => string;
  groupByKeys?: string[];
  onRenderGroup?: (group: string) => React.ReactNode;
  loading?: boolean;
  loadingItems?: number;
  emptyState?: React.ReactNode;
  enableKeyNav?: boolean;
  showBottomBorder?: boolean;
  selected?: T | null;
};

export const ListView = <T extends object, G extends keyof T>({
  items,
  onItemSelected,
  onRender,
  loading,
  groupByFn,
  groupByKeys,
  onRenderGroup,
  selected: itemSelected = null,
  loadingItems = 30,
  initialSelected = null,
  enableKeyNav = false,
  showBottomBorder = false,
  emptyState = <Text color="gray">No items</Text>,
}: ListViewProps<T, G>) => {
  const [selected, setSelected] = useState<T | null>(itemSelected);

  useEffect(() => {
    setSelected(itemSelected);
  }, [itemSelected]);

  // const groupedItems = groupByKeys?.map<string | T>(key => [key].concat(items.map(item => groupByFn(item) === key))).flat();
  const groupedItems = useMemo(
    () =>
      groupByKeys && groupByFn
        ? groupByKeys.flatMap<(string | T)[]>((key) => {
            const filtered = items.filter((item) => groupByFn?.(item) === key);
            // only show the key (i.e. group) if there are items
            const showKey = filtered.length > 0 ? [key] : [];
            return (showKey as Array<string | T>).concat(filtered);
          })
        : items,
    [groupByFn, groupByKeys, items],
  );

  const itemsWithoutGroups = useMemo(
    () => groupedItems.filter((item) => typeof item !== "string") as T[],
    [groupedItems],
  );

  const { setIndex } = useKeyNav({
    list: itemsWithoutGroups,
    initial: initialSelected,
    selected: itemSelected,
    onSelect: (item) => {
      setSelected(item ?? null);
      onItemSelected?.(item ?? null);
    },
    enabled: enableKeyNav,
  });

  useEffect(() => {
    if (!initialSelected) {
      setIndex(null);
      setSelected(null);
    }
  }, [setIndex, initialSelected]);

  return loading ? (
    <LoadingList loadingItems={loadingItems} />
  ) : !items?.length ? (
    <Centered className={cn(emptyState && "p-5")}>{emptyState}</Centered>
  ) : (
    <List className={showBottomBorder ? "" : "[&>:last-child]:border-b-0"}>
      {groupedItems.map((group) =>
        typeof group === "string"
          ? onRenderGroup?.(group) || (
              <ListItem className="bg-accent sticky top-0" key={group}>
                <ListItemLeft>
                  <Text color="gray">{group}</Text>
                </ListItemLeft>
              </ListItem>
            )
          : // biome-ignore lint/suspicious/noExplicitAny: TODO
            // biome-ignore lint/correctness/useJsxKeyInIterable: biome can't figure it out
            onRender(group as any, selected),
      )}
    </List>
  );
};

function LoadingList({ loadingItems }: { loadingItems: number }) {
  return (
    <List>
      {createArrayWithNumbers(loadingItems).map((num) => (
        <ListItem key={num}>
          <ListItemLeft className="gap-4">
            <Skeleton variant="heading" style={{ width: 20 }} />
            <Skeleton variant="heading" style={{ width: 100 }} />
          </ListItemLeft>
          <ListItemRight>
            <Skeleton variant="heading" style={{ width: 210 }} />
          </ListItemRight>
        </ListItem>
      ))}
    </List>
  );
}

function createArrayWithNumbers(length: number) {
  return Array.from({ length }, (_, k) => k + 1);
}
