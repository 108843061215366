import * as SheetPrimitive from "@radix-ui/react-dialog";
import { Cross1Icon } from "@radix-ui/react-icons";
import { type VariantProps, cva } from "class-variance-authority";
import * as React from "react";
import { twMerge } from "tailwind-merge";

function Sheet(
  props: React.ComponentPropsWithoutRef<typeof SheetPrimitive.Root>,
) {
  return <SheetPrimitive.Root {...props} />;
}

const SheetTrigger = SheetPrimitive.Trigger;

const portalVariants = cva("fixed inset-0 z-50 flex", {
  variants: {
    position: {
      top: "items-start",
      bottom: "items-end",
      left: "justify-start",
      right: "justify-end",
    },
  },
  defaultVariants: { position: "right" },
});

interface SheetPortalProps
  extends SheetPrimitive.DialogPortalProps,
    VariantProps<typeof portalVariants> {}

const SheetPortal = ({ position, children, ...props }: SheetPortalProps) => (
  <SheetPrimitive.Portal {...props}>
    <div className={portalVariants({ position })}>{children}</div>
  </SheetPrimitive.Portal>
);
// SheetPortal.displayName = SheetPrimitive.Portal.displayName;

const SheetOverlay = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Overlay>
>(({ className, children, ...props }, ref) => (
  <SheetPrimitive.Overlay
    className={twMerge(
      "fixed inset-0 z-50 bg-background/80 backdrop-blur-sm transition-all duration-100 data-[state=closed]:animate-out data-[state=closed]:fade-out data-[state=open]:fade-in",
      className,
    )}
    {...props}
    ref={ref}
  />
));
// SheetOverlay.displayName = SheetPrimitive.Overlay.displayName;

const sheetVariants = cva(
  "fixed z-50 scale-100 bg-background opacity-100 shadow-lg flex flex-col",
  {
    variants: {
      side: {
        top: "animate-in slide-in-from-top w-full duration-200 border-b",
        bottom: "animate-in slide-in-from-bottom w-full duration-200 border-t",
        left: "animate-in slide-in-from-left h-full duration-200 border-r",
        right: "animate-in slide-in-from-right h-full duration-200 border-l",
      },
      size: {
        content: "",
        sm: "",
        md: "",
        lg: "",
        xl: "",
        full: "",
      },
    },
    compoundVariants: [
      {
        side: ["top", "bottom"],
        size: "content",
        class: "max-h-screen",
      },
      {
        side: ["top", "bottom"],
        size: "sm",
        class: "h-1/4",
      },
      {
        side: ["top", "bottom"],
        size: "md",
        class: "h-1/3",
      },
      {
        side: ["top", "bottom"],
        size: "lg",
        class: "h-1/2",
      },
      {
        side: ["top", "bottom"],
        size: "xl",
        class: "h-5/6",
      },
      {
        side: ["top", "bottom"],
        size: "full",
        class: "h-screen",
      },
      {
        side: ["right", "left"],
        size: "content",
        class: "max-w-screen",
      },
      {
        side: ["right", "left"],
        size: "sm",
        class: "w-[300px]",
      },
      {
        side: ["right", "left"],
        size: "md",
        class: "w-[400px]",
      },
      {
        side: ["right", "left"],
        size: "lg",
        class: "w-[500px]",
      },
      {
        side: ["right", "left"],
        size: "xl",
        class: "w-[600px]",
      },
      {
        side: ["right", "left"],
        size: "full",
        class: "w-screen",
      },
    ],
    defaultVariants: {
      side: "right",
      size: "sm",
    },
  },
);

export interface DialogContentProps
  extends React.ComponentPropsWithoutRef<typeof SheetPrimitive.Content>,
    VariantProps<typeof sheetVariants> {}

const SheetContent = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Content>,
  DialogContentProps
>(({ side: position, size, className, children, ...props }, ref) => (
  <SheetPortal position={position}>
    <SheetOverlay />
    <SheetPrimitive.Content
      ref={ref}
      className={twMerge(sheetVariants({ side: position, size }), className)}
      {...props}
      onEscapeKeyDown={(evt) => {
        evt.stopPropagation();
        props.onEscapeKeyDown?.(evt);
      }}
    >
      {children}
    </SheetPrimitive.Content>
  </SheetPortal>
));
// SheetContent.displayName = SheetPrimitive.Content.displayName;

const SheetClose = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Close>,
  React.HTMLAttributes<HTMLButtonElement>
>((props, ref) => (
  <SheetPrimitive.Close
    {...props}
    ref={ref}
    className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-secondary"
  >
    <Cross1Icon className="h-4 w-4" />
    <span className="sr-only">Close</span>
  </SheetPrimitive.Close>
));

const SheetHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={twMerge(
      "flex flex-col space-y-2 text-center sm:text-left",
      className,
    )}
    {...props}
  />
);
SheetHeader.displayName = "SheetHeader";

const SheetFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={twMerge(
      "flex flex-col-reverse gap-2 sm:flex-row-reverse sm:justify-start sm:space-x-2 p-4 border-t",
      className,
    )}
    {...props}
  />
);
SheetFooter.displayName = "SheetFooter";

const SheetTitle = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Title>
>(({ className, ...props }, ref) => (
  <SheetPrimitive.Title
    ref={ref}
    className={twMerge(
      "text-lg font-semibold text-foreground h-[45px] px-4 inline-flex items-center border-b w-full flex-shrink-0",
      className,
    )}
    {...props}
  />
));
SheetTitle.displayName = SheetPrimitive.Title.displayName;

const SheetDescription = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Description>
>(({ className, ...props }, ref) => (
  <SheetPrimitive.Description
    ref={ref}
    className={twMerge("text-sm text-muted-foreground", className)}
    {...props}
  />
));
// SheetDescription.displayName = SheetPrimitive.Description.displayName;

const SheetBody = React.forwardRef<
  React.ElementRef<"div">,
  React.ComponentPropsWithoutRef<"div">
>(({ children, className, ...props }, ref) => {
  return (
    <div
      {...props}
      className={twMerge("p-4 overflow-y-auto flex-1", className)}
      ref={ref}
    >
      {children}
    </div>
  );
});
SheetBody.displayName = "SheetBody";

const Namespace = Object.assign(Sheet, {
  Trigger: SheetTrigger,
  Content: SheetContent,
  Header: SheetHeader,
  Footer: SheetFooter,
  Title: SheetTitle,
  Description: SheetDescription,
  Body: SheetBody,
  Close: SheetClose,
  CloseCustom: SheetPrimitive.Close,
});

export { Namespace as Flyout };
