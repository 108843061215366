import { type VariantProps, cva } from "class-variance-authority";
import React from "react";
import { twMerge } from "tailwind-merge";

export const iconStyles = cva("flex-shrink-0", {
  variants: {
    color: {
      default: "text-muted-foreground",
      gray: "text-muted-foreground",
      primary: "text-primary",
      red: "text-red-600 dark:text-red-700",
      green: "text-green-600 dark:text-green-700",
      yellow: "text-yellow-600 dark:text-yellow-700",
      blue: "text-indigo-600 dark:text-indigo-700",
    },
  },
});

export const badgeStyles = cva("", {
  variants: {
    color: {
      default: "bg-muted text-muted-foreground",
      gray: "bg-muted text-muted-foreground",
      primary: "text-primary",
      red: "bg-red-100 text-red-700 dark:bg-red-400/10 dark:text-red-400",
      green:
        "bg-green-100 text-green-700 dark:bg-green-400/10 dark:text-green-400",
      yellow:
        "bg-yellow-100 text-yellow-700 dark:bg-yellow-400/10 dark:text-yellow-400",
      blue: "bg-indigo-100 text-indigo-700 dark:bg-indigo-400/10 dark:text-indigo-400",
    },
  },
});

// biome-ignore lint/suspicious/noExplicitAny: it's ok
export function withStyles(Icon: React.ComponentType<any>) {
  return React.forwardRef<
    SVGSVGElement,
    Omit<React.ComponentPropsWithoutRef<"svg">, "color"> &
      VariantProps<typeof iconStyles>
  >(({ color, className, ...props }, ref) => {
    return (
      <Icon
        {...props}
        ref={ref}
        className={twMerge(iconStyles({ color }), className)}
      />
    );
  });
}

export const statusMap: Record<
  string,
  VariantProps<typeof iconStyles>["color"]
> = {
  new: "default",
  received: "default",
  in_progress: "yellow",
  completed: "green",
  reported: "blue",
  final: "blue",
  cancelled: "default",
};
