import React from "react";
import { twMerge } from "tailwind-merge";

import { useEnsureRef } from "../utils";

import { InputWrapper, type InputWrapperBaseProps } from "./InputWrapper";

export type TextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> &
  InputWrapperBaseProps & {
    minRows?: number;
    autoExpand?: boolean;
  };

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, label, autoExpand, helpText, error, ...props }, ref) => {
    const textAreaRef = useEnsureRef(ref);

    // useAutosizeTextArea(textAreaRef.current, value, {
    //   minRows,
    //   enabled: autoExpand,
    // });

    return (
      <InputWrapper label={label} error={error} helpText={helpText}>
        <textarea
          className={twMerge(
            "flex h-20 w-full rounded-md border border-input bg-transparent px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
            className,
          )}
          {...props}
          ref={textAreaRef}
        />
      </InputWrapper>
    );
  },
);
Textarea.displayName = "Textarea";

export { Textarea };
