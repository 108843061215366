import { type VariantProps, cva } from "class-variance-authority";
import { type ElementType, type ForwardedRef, forwardRef } from "react";
import { twMerge } from "tailwind-merge";

import type { PolymorphicComponentPropsWithRef } from "../utils/polymorphic";

const styles = cva("m-0 font-normal", {
  variants: {
    size: {
      "1": "text-xs",
      "2": "text-[13px]",
      "3": "text-sm",
      "4": "text-base",
      "5": "text-lg",
      "6": "text-xl",
      "7": "text-2xl",
      "8": "text-3xl",
      "9": "text-4xl",
    },
    color: {
      red: "text-red-600",
      pink: "text-pink-600",
      purple: "text-purple-600",
      violet: "text-violet-600",
      indigo: "text-indigo-600",
      blue: "text-blue-600",
      cyan: "text-cyan-600",
      teal: "text-teal-600",
      green: "text-green-600",
      lime: "text-lime-600",
      yellow: "text-yellow-600",
      orange: "text-orange-600",
      brown: "text-brown-600",
      gray: "text-muted-foreground",
      slate: "text-slate-600",
      white: "text-white",
      black: "text-foreground",
      primary: "text-primary",
      destructive: "text-destructive",
    },
    bold: {
      true: "font-medium",
    },
    bolder: {
      true: "font-semibold",
    },
    ellipsis: {
      true: "truncate",
    },
    strikethrough: {
      true: "line-through",
    },
    flex: {
      true: "inline-flex items-center gap-2",
    },
    mono: {
      true: "tabular-nums",
    },
  },
  defaultVariants: {
    size: "2",
    color: "black",
  },
});

type TextProps<As extends ElementType> = PolymorphicComponentPropsWithRef<
  As,
  VariantProps<typeof styles>
>;

type TextComponent = <As extends ElementType = "span">(
  props: TextProps<As>,
) => React.ReactElement | null;

export const Text = forwardRef(
  <As extends ElementType = "span">(
    {
      as,
      size,
      color,
      ellipsis,
      flex,
      mono,
      bold,
      bolder,
      strikethrough,
      ...props
    }: TextProps<As>,
    ref: ForwardedRef<HTMLSpanElement>,
  ) => {
    const Comp = as || "span";
    return (
      <Comp
        {...props}
        className={twMerge(
          styles({
            size,
            color,
            ellipsis,
            flex,
            mono,
            bold,
            bolder,
            strikethrough,
          }),
          props.className,
        )}
        ref={ref}
      />
    );
  },
) as TextComponent;
