import React from "react";

import { cn } from "../utils";

import { Centered } from "./Centered";
import { Text } from "./Text";

type EmptyStateProps = {
  text?: React.ReactNode;
  className?: string;
} & React.ComponentPropsWithoutRef<typeof Centered>;

export const EmptyState = React.forwardRef<HTMLDivElement, EmptyStateProps>(
  ({ children, className, text = "No items", ...props }, ref) => {
    return (
      <Centered
        {...props}
        className={cn(
          "p-5 flex-col gap-4 bg-muted/50 rounded-md mt-2",
          className,
        )}
        ref={ref}
      >
        {typeof text === "string" ? (
          <Text color="gray" size="2">
            {text}
          </Text>
        ) : (
          text
        )}
        {children}
      </Centered>
    );
  },
);
