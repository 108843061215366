import { forwardRef } from "react";

import { cn } from "../utils";

import { Flex } from "./Flex";

type CenteredProps = React.ComponentProps<typeof Flex>;

export const Centered = forwardRef<HTMLDivElement, CenteredProps>(
  (props, ref) => {
    return (
      <Flex
        {...props}
        align="center"
        justify="center"
        className={cn("h-full w-full", props.className)}
        ref={ref}
      />
    );
  },
);
