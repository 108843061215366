import { InfoIcon } from "lucide-react";
import React from "react";
import { twMerge } from "tailwind-merge";

import { Box } from "./Box";
import { Flex } from "./Flex";
import { Label } from "./Label";
import { Text } from "./Text";
import { Tooltip } from "./Tooltip";

export type InputWrapperBaseProps = {
  label?: React.ReactNode;
  helpText?: string;
  error?: string;
  labelProps?: React.ComponentPropsWithoutRef<typeof Label>;
};

type InputWrapperProps = InputWrapperBaseProps &
  React.ComponentPropsWithoutRef<typeof Box>;

export const InputWrapper = React.forwardRef<HTMLDivElement, InputWrapperProps>(
  ({ label, error, id, labelProps, helpText, ...props }, ref) => {
    return (
      <Box
        ref={ref}
        {...props}
        className={twMerge(
          "w-full grid gap-1.5",
          error && "[&>input]:border-destructive",
          props.className,
        )}
      >
        {label && (
          <Flex align="center" gap="2">
            <Label htmlFor={id} error={!!error} {...labelProps}>
              {label}
            </Label>
            {helpText && (
              <Tooltip>
                <Tooltip.Trigger asChild>
                  <InfoIcon className="size-3" color="gray" />
                </Tooltip.Trigger>
                <Tooltip.Content>{helpText}</Tooltip.Content>
              </Tooltip>
            )}
          </Flex>
        )}
        {props.children}
        {error && (
          <Text color="destructive" size="2">
            {error}
          </Text>
        )}
      </Box>
    );
  },
);
