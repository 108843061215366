import * as AvatarPrimitive from "@radix-ui/react-avatar";
import { type VariantProps, cva } from "class-variance-authority";
import { UserIcon } from "lucide-react";
import * as React from "react";
import { twMerge } from "tailwind-merge";

const avatarStyles = cva(
  ["relative flex shrink-0 overflow-hidden rounded-full"],
  {
    variants: {
      color: {
        red: "bg-red-100 dark:bg-red-400/50",
        orange: "bg-orange-100 dark:bg-orange-400/50",
        amber: "bg-amber-100 dark:bg-amber-400/50",
        yellow: "bg-yellow-100 dark:bg-yellow-400/50",
        lime: "bg-lime-100 dark:bg-lime-400/50",
        green: "bg-green-100 dark:bg-green-400/50",
        emerald: "bg-emerald-100 dark:bg-emerald-400/50",
        teal: "bg-teal-100 dark:bg-teal-400/50",
        cyan: "bg-cyan-100 dark:bg-cyan-400/50",
        sky: "bg-sky-100 dark:bg-sky-400/50",
        blue: "bg-blue-100 dark:bg-blue-400/50",
        indigo: "bg-indigo-100 dark:bg-indigo-400/50",
        violet: "bg-violet-100 dark:bg-violet-400/50",
        purple: "bg-purple-100 dark:bg-purple-400/50",
        fuchsia: "bg-fuchsia-100 dark:bg-fuchsia-400/50",
        pink: "bg-pink-100 dark:bg-pink-400/50",
        rose: "bg-rose-100 dark:bg-rose-400/50",
        muted: "bg-muted",
      },
      size: {
        "1": "h-6 w-6",
        "2": "h-7 w-7",
        "3": "h-8 w-8",
      },
    },
    defaultVariants: {
      size: "2",
      color: "muted",
    },
  },
);

const fallbackStyles = cva(
  ["flex h-full w-full items-center justify-center rounded-full font-medium"],
  {
    variants: {
      size: {
        "1": "text-[11px]",
        "2": "text-xs",
        "3": "text-sm",
      },
    },
    defaultVariants: {
      size: "2",
    },
  },
);

const colors = [
  "red",
  "orange",
  "amber",
  "yellow",
  "lime",
  "green",
  "emerald",
  "teal",
  "cyan",
  "sky",
  "blue",
  "indigo",
  "violet",
  "purple",
  "fuchsia",
  "pink",
  "rose",
] as const;

type AvatarContextValue = {
  size: VariantProps<typeof avatarStyles>["size"];
};

const AvatarContext = React.createContext({} as AvatarContextValue);

type AvatarProps = React.ComponentProps<typeof AvatarPrimitive.Root> &
  VariantProps<typeof avatarStyles> & {
    randomColorId?: number;
  };

const Avatar = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Root>,
  AvatarProps
>(({ className, size, color, randomColorId, ...props }, ref) => {
  const colorFromId = randomColorId ? getColorFromId(randomColorId) : undefined;
  color = colorFromId || color;
  return (
    <AvatarContext.Provider value={{ size }}>
      <AvatarPrimitive.Root
        ref={ref}
        className={twMerge(avatarStyles({ size, color }), className)}
        {...props}
      />
    </AvatarContext.Provider>
  );
});
Avatar.displayName = AvatarPrimitive.Root.displayName;

const AvatarImage = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Image>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Image
    ref={ref}
    className={twMerge("aspect-square h-full w-full", className)}
    {...props}
  />
));
AvatarImage.displayName = AvatarPrimitive.Image.displayName;

type FallbackProps = React.ComponentPropsWithoutRef<
  typeof AvatarPrimitive.Fallback
> &
  VariantProps<typeof fallbackStyles>;

const AvatarFallback = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Fallback>,
  FallbackProps
>(({ className, size, ...props }, ref) => {
  const { size: contextSize } = React.useContext(AvatarContext);
  return (
    <AvatarPrimitive.Fallback
      ref={ref}
      className={twMerge(
        fallbackStyles({ size: size ?? contextSize }),
        className,
      )}
      {...props}
    />
  );
});
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;

function seededRandom(seed: number) {
  const x = Math.sin(seed) * 10000;
  return x - Math.floor(x);
}

export function getColorFromId(id: number) {
  const randomIndex = Math.floor(seededRandom(id) * colors.length);
  return colors[randomIndex];
}

export function getAvatarFallback(name?: string | null): React.ReactNode {
  const [first, last] = name?.split(" ") || [];
  const initials = `${first?.[0] || ""}${last?.[0] || ""}`;
  if (initials) {
    return initials.toUpperCase();
  }
  return <UserIcon className="size-4" />;
}

const Namespace = Object.assign(Avatar, {
  Image: AvatarImage,
  Fallback: AvatarFallback,
});

export { Namespace as Avatar };
