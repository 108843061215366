import { type VariantProps, cva } from "class-variance-authority";
import React, { type HTMLAttributes, forwardRef } from "react";
import { twMerge } from "tailwind-merge";

import type {
  PolymorphicComponentPropsWithRef,
  PolymorphicRef,
} from "../../utils/polymorphic";

const listItemStyles = cva(
  ["flex items-center h-10 outline-none", "hover:bg-muted/50 "],
  {
    variants: {
      noBorder: {
        true: "border-b-0 hover:bg-inherit",
      },
    },
  },
);

export type ListItemProps<As extends React.ElementType = "div"> =
  PolymorphicComponentPropsWithRef<As, VariantProps<typeof listItemStyles>>;

type ListItemComponent = <As extends React.ElementType = "div">(
  props: ListItemProps<As>,
) => React.ReactElement | null;

export const ListItem = React.forwardRef(
  <As extends React.ElementType = "div">(
    { as, noBorder, className, ...props }: ListItemProps<As>,
    ref: PolymorphicRef<As>,
  ) => {
    const Comp = as || "div";
    return (
      <Comp
        {...props}
        ref={ref}
        className={twMerge(listItemStyles({ noBorder }), className)}
      />
    );
  },
) as ListItemComponent;

type ListItemSectionProps = HTMLAttributes<HTMLDivElement>;

export const ListItemLeft = forwardRef<HTMLDivElement, ListItemSectionProps>(
  (props, ref) => {
    return (
      <div
        {...props}
        ref={ref}
        className={twMerge(
          ["flex items-center flex-1 px-3 gap-2 min-w-0"],
          props.className,
        )}
      />
    );
  },
);

export const ListItemRight = forwardRef<HTMLDivElement, ListItemSectionProps>(
  (props, ref) => {
    return (
      <div
        {...props}
        ref={ref}
        className={twMerge(
          ["flex items-center flex-shrink-0 px-3 gap-2"],
          props.className,
        )}
      />
    );
  },
);

export const ListItemActions = forwardRef<HTMLDivElement, ListItemSectionProps>(
  (props, ref) => {
    return (
      <div
        {...props}
        ref={ref}
        className={twMerge(
          ["flex items-center justify-end px-3 border-l gap-2 h-full"],
          props.className,
        )}
      />
    );
  },
);
