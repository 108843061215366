import { type VariantProps, cva } from "class-variance-authority";
import { type ElementType, type ReactElement, forwardRef } from "react";
import { twMerge } from "tailwind-merge";

import type {
  PolymorphicComponentPropsWithRef,
  PolymorphicRef,
} from "../utils/polymorphic";

const styles = cva("flex", {
  variants: {
    direction: {
      row: "flex-row",
      column: "flex-col",
    },
    align: {
      start: "items-start",
      center: "items-center",
      end: "items-end",
      stretch: "items-stretch",
      baseline: "items-baseline",
    },
    justify: {
      start: "justify-start",
      center: "justify-center",
      end: "justify-end",
      between: "justify-between",
    },
    wrap: {
      noWrap: "flex-nowrap",
      wrap: "flex-wrap",
      wrapReverse: "flex-wrap-reverse",
    },
    gap: {
      "1": "gap-1",
      "2": "gap-2",
      "3": "gap-3",
      "4": "gap-4",
      "5": "gap-5",
      "6": "gap-6",
      "7": "gap-7",
      "8": "gap-8",
      "9": "gap-9",
    },
  },
  defaultVariants: {
    direction: "row",
    align: "stretch",
    justify: "start",
    wrap: "noWrap",
  },
});

type FlexProps<As extends ElementType> = PolymorphicComponentPropsWithRef<
  As,
  VariantProps<typeof styles>
>;

type FlexComponent = <As extends ElementType = "div">(
  props: FlexProps<As>,
) => ReactElement | null;

export const Flex = forwardRef(
  <As extends ElementType = "div">(
    { direction, align, justify, wrap, gap, as, ...props }: FlexProps<As>,
    ref?: PolymorphicRef<As>,
  ) => {
    const Comp = as || "div";

    return (
      <Comp
        {...props}
        className={twMerge(
          styles({ direction, align, justify, wrap, gap }),
          props.className,
        )}
        ref={ref}
      />
    );
  },
) as FlexComponent;
