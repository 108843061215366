import { type ComponentPropsWithoutRef, forwardRef } from "react";

import { Input, InputWrapper, type InputWrapperBaseProps } from ".";

export type TextInputProps = InputWrapperBaseProps &
  ComponentPropsWithoutRef<typeof Input>;

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({ label, helpText, ...props }, ref) => {
    return (
      <InputWrapper
        label={label}
        id={props.id}
        error={props.error}
        helpText={helpText}
      >
        <Input {...props} ref={ref} />
      </InputWrapper>
    );
  },
);
