import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { type VariantProps, cva } from "class-variance-authority";
import { CheckIcon, MinusIcon } from "lucide-react";
import * as React from "react";
import { twMerge } from "tailwind-merge";

import { cn } from "../utils";
import { Text } from "./Text";

const styles = cva(
  [
    "peer shrink-0 border border-primary ring-offset-background",
    "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
    "data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground",
  ],
  {
    variants: {
      size: {
        "1": "h-4 w-4 rounded-sm",
        "2": "h-5 w-5 rounded",
        "3": "h-6 w-6 rounded-md",
      },
    },
    defaultVariants: {
      size: "2",
    },
  },
);

type CheckboxLabelProps = {
  label?: string;
  description?: string;
  error?: string;
};

const CheckboxLabel = React.forwardRef<
  React.ElementRef<"label">,
  React.ComponentPropsWithoutRef<"label"> & CheckboxLabelProps
>(({ children, label, description, error, ...props }, ref) =>
  label ? (
    <div>
      <label className="flex gap-2 items-center" {...props} ref={ref}>
        {children}
        <div className="flex flex-col">
          <Text>{label}</Text>
          {description && (
            <Text size="1" color="gray">
              {description}
            </Text>
          )}
        </div>
      </label>
      {error && (
        <Text color="red" size="2">
          {error}
        </Text>
      )}
    </div>
  ) : (
    <>{children}</>
  ),
);

type CheckboxProps = React.ComponentPropsWithoutRef<
  typeof CheckboxPrimitive.Root
> &
  VariantProps<typeof styles> &
  CheckboxLabelProps;

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxProps
>(({ className, size, label, error, description, ...props }, ref) => (
  <CheckboxLabel label={label} description={description} error={error}>
    <CheckboxPrimitive.Root
      ref={ref}
      className={twMerge(styles({ size }), className)}
      {...props}
    >
      <CheckboxPrimitive.Indicator
        className={twMerge("flex items-center justify-center")}
      >
        {props.checked === "indeterminate" ? (
          // biome-ignore lint/suspicious/noDoubleEquals: can be both string or number
          <MinusIcon className={cn(size == "1" ? "size-3" : "size-4")} />
        ) : props.checked ? (
          // biome-ignore lint/suspicious/noDoubleEquals: can be both string or number
          <CheckIcon className={cn(size == "1" ? "size-3" : "size-4")} />
        ) : null}
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
  </CheckboxLabel>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
