import { type Ref, type SVGProps, forwardRef, memo } from "react";
import { withStyles } from "./utils/styles";

const StatusFinalIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    height={24}
    width={24}
    ref={ref}
    {...props}
  >
    <path
      d="M12 0a12 12 0 1 0 12 12A12 12 0 0 0 12 0Zm6.93 8.2 -6.85 9.29a1 1 0 0 1 -1.43 0.19l-4.89 -3.91a1 1 0 0 1 -0.15 -1.41A1 1 0 0 1 7 12.21l4.08 3.26L17.32 7a1 1 0 0 1 1.39 -0.21 1 1 0 0 1 0.22 1.41Z"
      fill="currentcolor"
      strokeWidth={1}
    />
  </svg>
);

const ForwardRef = forwardRef(StatusFinalIcon);
const Memo = withStyles(memo(ForwardRef));
export { Memo as StatusFinalIcon };
