import { useFocusRing } from "@react-aria/focus";
import {
  type AriaGridListItemOptions,
  type AriaGridListProps,
  useGridList,
  useGridListItem,
  useGridListSelectionCheckbox,
} from "@react-aria/gridlist";
import { mergeProps } from "@react-aria/utils";
import { type ListState, useListState } from "@react-stately/list";
import type { Key, Node } from "@react-types/shared";
import React, { useCallback, useContext, useEffect, useRef } from "react";
import { twMerge } from "tailwind-merge";

import { Centered, Checkbox, Flex, Spinner } from "../base";
import { EmptyState } from "../base/EmptyState";
import { useTinyKeys } from "../hooks";

interface ListViewContextValue<T> {
  state: ListState<T>;
  hideCheckbox?: boolean;
  dividers: boolean;
  onAction?: (key: Key) => void;
}

const ListViewContext = React.createContext<ListViewContextValue<unknown>>(
  {} as ListViewContextValue<unknown>,
);

export function GridList<T extends object>({
  selected,
  isLoading,
  scrollMarginTop,
  emptyState = <EmptyState />,
  alternateKeys,
  autoFocus,
  bottomBorder,
  hideCheckbox,
  className,
  dividers = true,
  onFocusedKeyChanged,
  ...props
}: AriaGridListProps<T> & {
  selected?: Key;
  isLoading?: boolean;
  scrollMarginTop?: number;
  emptyState?: React.ReactNode;
  hideCheckbox?: boolean;
  bottomBorder?: boolean;
  alternateKeys?: boolean;
  autoFocus?: boolean;
  dividers?: boolean;
  className?: string;
  onFocusedKeyChanged?: (key: React.Key | undefined) => void;
}) {
  const state = useListState(props);
  const ref = useRef<HTMLDivElement>(null);
  const initialSelected = useRef(false);
  const lastFocusedRef = useRef<React.Key | undefined>();

  const { gridProps } = useGridList(props, state, ref);

  // const scrollClass = css({
  //   "&:focus": {
  //     scrollMarginTop,
  //   },
  // });

  useAutoFocus({ enabled: autoFocus, state, ref });
  useAlternateKeys({ enabled: alternateKeys, autoFocus, state, ref });

  useEffect(() => {
    const focusedKey = state.selectionManager.focusedKey;
    if (focusedKey !== lastFocusedRef.current) {
      lastFocusedRef.current = focusedKey;
      onFocusedKeyChanged?.(focusedKey);
    }
  }, [onFocusedKeyChanged, state.selectionManager.focusedKey]);

  useEffect(() => {
    if (selected && !initialSelected.current) {
      state.selectionManager.setFocused(true);
      state.selectionManager.setFocusedKey(selected);
      initialSelected.current = true;
    }
  }, [selected, state.selectionManager]);

  return (
    <ListViewContext.Provider
      value={{
        state,
        hideCheckbox,
        dividers,
        onAction: props.onAction,
      }}
    >
      <div
        {...gridProps}
        ref={ref}
        className={twMerge(
          // scrollClass(),
          dividers && "divide-y",
          bottomBorder && "border-b",
          "focus-visible:outline-none",
          className,
        )}
      >
        {isLoading ? (
          <Centered className="p-5">
            <Spinner color="primary" />
          </Centered>
        ) : state.collection.size === 0 ? (
          emptyState
        ) : (
          <>
            {[...state.collection].map((item) => (
              <GridListItem key={item.key} node={item} />
            ))}
          </>
        )}
      </div>
    </ListViewContext.Provider>
  );
}

function GridListItem({ node }: AriaGridListItemOptions) {
  const { state, hideCheckbox } = useContext(ListViewContext);
  const ref = useRef(null);
  const { rowProps, gridCellProps, isPressed, isSelected, isDisabled } =
    useGridListItem({ node, isVirtualized: false }, state, ref);
  const { isFocusVisible, focusProps } = useFocusRing();
  const showCheckbox =
    state.selectionManager.selectionMode !== "none" &&
    state.selectionManager.selectionBehavior === "toggle";

  return (
    <div
      {...mergeProps(rowProps, focusProps)}
      ref={ref}
      className={twMerge(
        "outline-none flex items-center hover:bg-muted/50",
        isPressed && "bg-muted",
        isFocusVisible && "ring-2 ring-ring ring-inset",
        isSelected && "bg-muted",
        isDisabled && "opacity-50",
      )}
    >
      <div {...gridCellProps} style={{ width: "100%" }}>
        {showCheckbox && !hideCheckbox ? (
          <Flex align="center" gap="2" className="grid-list-checkbox px-2">
            <GridListCheckbox node={node} />
            {node.rendered}
          </Flex>
        ) : (
          node.rendered
        )}
      </div>
    </div>
  );
}

function GridListCheckbox({ node }: { node: Node<unknown> }) {
  const { state } = useContext(ListViewContext);
  const { checkboxProps } = useGridListSelectionCheckbox(
    { key: node.key },
    state,
  );
  const { onChange, isSelected, isDisabled, ...otherProps } = checkboxProps;
  return <Checkbox {...otherProps} size="1" checked={isSelected} />;
}

function useAutoFocus({
  enabled,
  state,
  ref,
}: {
  enabled: boolean | undefined;
  state: ListState<unknown>;
  ref: React.RefObject<HTMLElement>;
}) {
  useTinyKeys(
    {
      ArrowDown: (evt) => {
        if (enabled && !state.selectionManager.isFocused) {
          evt.preventDefault();
          ref.current?.focus();
        }
      },
      ArrowUp: (evt) => {
        if (enabled && !state.selectionManager.isFocused) {
          evt.preventDefault();
          ref.current?.focus();
        }
      },
    },
    [state.selectionManager.focusedKey, state.collection, enabled],
  );
}

function useAlternateKeys({
  enabled,
  autoFocus,
  state,
  ref,
}: {
  enabled: boolean | undefined;
  autoFocus?: boolean;
  state: ListState<unknown>;
  ref: React.RefObject<HTMLElement>;
}) {
  const next = useCallback(
    (evt: KeyboardEvent) => {
      if (!enabled) return;
      if (autoFocus && !state.selectionManager.isFocused) {
        evt.preventDefault();
        ref.current?.focus();
        return;
      }
      const currentKey = state.selectionManager.focusedKey;
      if (currentKey) {
        const nextKey = state.collection.getKeyAfter(currentKey);
        if (nextKey) {
          state.selectionManager.setFocusedKey(nextKey);
        }
      }
    },
    [autoFocus, enabled, ref, state.collection, state.selectionManager],
  );
  const previous = useCallback(
    (evt: KeyboardEvent) => {
      if (!enabled) return;
      if (autoFocus && !state.selectionManager.isFocused) {
        evt.preventDefault();
        ref.current?.focus();
        return;
      }
      const currentKey = state.selectionManager.focusedKey;
      if (currentKey) {
        const nextKey = state.collection.getKeyBefore(currentKey);
        if (nextKey) {
          state.selectionManager.setFocusedKey(nextKey);
        }
      }
    },
    [autoFocus, enabled, ref, state.collection, state.selectionManager],
  );
  useTinyKeys(
    {
      J: next,
      K: previous,
    },
    [state.selectionManager.focusedKey, state.collection, enabled],
  );
}

export type SelectionChangeHandler = (keys: "all" | Set<Key>) => void;
