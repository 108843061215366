import { type VariantProps, cva } from "class-variance-authority";
import type React from "react";
import { type ElementType, type ForwardedRef, forwardRef } from "react";
import { twMerge } from "tailwind-merge";

import type { PolymorphicComponentPropsWithRef } from "../utils/polymorphic";

const styles = cva(
  [
    "inline-flex items-center justify-center flex-shrink-0 tabular-nums font-medium outline-none",
    "focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-ring",
  ],
  {
    variants: {
      variant: {
        pill: "rounded-full",
        circle: "rounded-full",
        rounded: "rounded",
      },
      size: {
        "0": "h-4 px-1.5 text-[0.6rem]",
        "1": "h-5 px-2 text-xs",
        "2": "h-6 px-3 text-sm",
      },
      color: {
        primary: "bg-primary text-primary-foreground",
        blue: "bg-blue-100 text-blue-700 dark:bg-blue-400/10 dark:text-blue-400",
        gray: "bg-muted text-muted-foreground",
        green:
          "bg-green-100 text-green-700 dark:bg-green-400/10 dark:text-green-400",
        red: "bg-red-100 text-red-700 dark:bg-red-400/10 dark:text-red-400",
        orange:
          "bg-orange-100 text-orange-700 dark:bg-orange-400/10 dark:text-orange-400",
        amber:
          "bg-amber-100 text-amber-700 dark:bg-amber-400/10 dark:text-amber-400",
        yellow:
          "bg-yellow-100 text-yellow-800 dark:bg-yellow-400/10 dark:text-yellow-400",
        indigo:
          "bg-indigo-100 text-indigo-700 dark:bg-indigo-400/10 dark:text-indigo-400",
      },
    },
    defaultVariants: {
      size: "1",
      color: "gray",
      variant: "pill",
    },
  },
);

export type BadgeProps<As extends ElementType = "span"> =
  PolymorphicComponentPropsWithRef<As, VariantProps<typeof styles>>;

type BadgeComponent = <As extends ElementType = "span">(
  props: BadgeProps<As>,
) => React.ReactElement | null;

export const Badge = forwardRef(
  <As extends ElementType = "span">(
    { size, color, variant, as, ...props }: BadgeProps<As>,
    ref: ForwardedRef<HTMLSpanElement>,
  ) => {
    const Comp = as || "span";
    return (
      <Comp
        {...props}
        className={twMerge(styles({ size, color, variant }), props.className)}
        ref={ref}
      />
    );
  },
) as BadgeComponent;
