import { Slot } from "@radix-ui/react-slot";
import React, {
  useCallback,
  useEffect,
  useRef,
  type ReactNode,
  memo,
  type MouseEvent,
  type MouseEventHandler,
} from "react";

import { EmptyState, Flex, Skeleton } from "../base";
import {
  FocusManagerProvider,
  useFocusManagerItem,
  useFocusManagerState,
} from "../hooks";
import { cn } from "../utils";

type LinkListProps<T extends object> = {
  items: T[];
  isLoading?: boolean;
  focusOnHover?: boolean;
  className?: string;
  emptyState?: ReactNode;
  children: (item: T) => ReactNode;
  LoadingComponent?: React.FunctionComponent;
  loadingItems?: number;
  initialIndex?: number;
  autoFocus?: boolean;
};

export function NavList<T extends object>({
  children,
  items,
  isLoading,
  focusOnHover,
  className,
  emptyState = <EmptyState />,
  LoadingComponent = DefaultLoadingRow,
  loadingItems = 10,
  initialIndex,
  autoFocus,
}: LinkListProps<T>) {
  const ref = useRef<HTMLDivElement>(null);
  const { itemRefs, containerProps, ...state } = useFocusManagerState(ref, {
    autoFocus,
  });

  const mouseEnterHover = useCallback(
    (e: MouseEvent) => {
      const index = itemRefs.current.indexOf(e.target as HTMLElement);
      if (index !== -1) {
        state.setFocusedIndex(index);
      }
    },
    [itemRefs, state.setFocusedIndex],
  );

  const onMouseEnter = focusOnHover ? mouseEnterHover : undefined;

  // biome-ignore lint/correctness/useExhaustiveDependencies: only run once
  useEffect(() => {
    if (initialIndex !== undefined && initialIndex >= 0) {
      state.setFocusedIndex(initialIndex);
    }
  }, []);

  const childrenWithIndex = items.map((item, index) =>
    React.cloneElement(children(item) as React.ReactElement, {
      index,
      onMouseEnter,
    }),
  );

  return (
    <FocusManagerProvider state={state}>
      <div
        {...containerProps}
        role="list"
        className={cn(
          "inline-block w-full",
          items.length && "border-b",
          className,
        )}
      >
        {isLoading
          ? new Array(loadingItems)
              .fill(0)
              .map((_, idx) => <LoadingComponent key={idx} />)
          : items.length === 0
            ? emptyState
            : childrenWithIndex}
      </div>
    </FocusManagerProvider>
  );
}

type NavListItemProps = {
  children?: ReactNode;
  asChild?: boolean;
};

type NavListItemInnerProps = {
  index: number;
  children?: ReactNode;
  asChild?: boolean;
  onMouseEnter?: MouseEventHandler;
  className?: string;
};

export type NavListItemSharedProps = React.ComponentProps<typeof Flex>;

function NavListItem({ children, asChild, ...props }: NavListItemProps) {
  const { index, ...innerProps } = props as NavListItemInnerProps;
  const ref = useRef<HTMLDivElement | null>(null);

  useFocusManagerItem(ref, index);

  const Comp = (asChild ? Slot : Flex) as typeof Flex; // TODO fix this cast

  return (
    <Comp
      ref={ref}
      // onFocus={handleFocus}
      role="listitem"
      {...innerProps}
      className={cn(
        "outline-none focus-visible:ring-2 ring-inset ring-ring hover:bg-muted/50",
        innerProps.className,
      )}
    >
      {children}
    </Comp>
  );
}

const _NavListItem = memo(NavListItem);
export { _NavListItem as NavListItem };

function DefaultLoadingRow() {
  return (
    <div className="flex items-center h-8 px-3 justify-between">
      <Skeleton variant="heading" style={{ width: 100 }} />
    </div>
  );
}
