import * as TabsPrimitive from "@radix-ui/react-tabs";
import * as React from "react";
import { twMerge } from "tailwind-merge";

type TabsContextValue = {
  orientation: "horizontal" | "vertical";
};

const TabsContext = React.createContext({} as TabsContextValue);

const Tabs = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Root>
>(({ className, orientation, ...props }, ref) => (
  <TabsContext.Provider value={{ orientation: orientation || "horizontal" }}>
    <TabsPrimitive.Root
      ref={ref}
      orientation={orientation}
      className={twMerge(className)}
      {...props}
    />
  </TabsContext.Provider>
));

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => {
  const { orientation } = React.useContext(TabsContext);
  return (
    <TabsPrimitive.List
      ref={ref}
      className={twMerge(
        "flex text-muted-foreground",
        orientation === "horizontal"
          ? "border-b items-center w-full h-10"
          : "border-r flex-col h-full",
        className,
      )}
      {...props}
    />
  );
});
TabsList.displayName = TabsPrimitive.List.displayName;

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => {
  const { orientation } = React.useContext(TabsContext);
  return (
    <TabsPrimitive.Trigger
      ref={ref}
      className={twMerge(
        "inline-flex items-center whitespace-nowrap px-3 text-sm font-medium ring-offset-background border-transparent",
        "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-0 disabled:pointer-events-none disabled:opacity-50",
        "data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:border-primary",
        orientation === "horizontal"
          ? "border-b-2 rounded-t justify-center h-10"
          : "border-r-2 rounded-none justify-start h-8 w-full -mr-[1px]",
        className,
      )}
      {...props}
    />
  );
});
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    tabIndex={-1}
    ref={ref}
    className={twMerge(
      "ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
      className,
    )}
    {...props}
  />
));
TabsContent.displayName = TabsPrimitive.Content.displayName;

const Namespace = Object.assign(Tabs, {
  List: TabsList,
  Trigger: TabsTrigger,
  Content: TabsContent,
});

export { Namespace as Tabs };
