import { type VariantProps, cva } from "class-variance-authority";
import React from "react";
import { twMerge } from "tailwind-merge";

const styles = cva(
  [
    "bg-muted rounded inline-flex items-center justify-center uppercase [&+&]:ml-1",
  ],
  {
    variants: {
      size: {
        xs: "text-xs px-1.5 py-0.5",
        sm: "text-sm px-2 py-0.5",
        md: "text-sm px-3 py-1",
      },
    },
    defaultVariants: {
      size: "xs",
    },
  },
);

const Kbd = React.forwardRef<
  React.ElementRef<"kbd">,
  React.ComponentPropsWithoutRef<"kbd"> & VariantProps<typeof styles>
>(({ size, className, ...props }, ref) => (
  <kbd {...props} ref={ref} className={twMerge(styles({ size }), className)} />
));

export { Kbd };
