import { type VariantProps, cva } from "class-variance-authority";
import { type ForwardedRef, forwardRef } from "react";
import { twMerge } from "tailwind-merge";

import type { PolymorphicComponentPropsWithRef } from "../utils/polymorphic";

const styles = cva("", {
  variants: {
    flex: {
      true: "flex items-center",
    },
    right: {
      true: "justify-end",
    },
    full: {
      true: "w-full",
    },
    gap: {
      "0": "gap-0",
      "1": "gap-1",
      "2": "gap-2",
      "3": "gap-3",
      "4": "gap-4",
    },
  },
});

type BoxProps<As extends React.ElementType> = PolymorphicComponentPropsWithRef<
  As,
  VariantProps<typeof styles>
>;

type BoxComponent = <As extends React.ElementType = "div">(
  props: BoxProps<As>,
) => React.ReactElement | null;

export const Box = forwardRef(
  <As extends React.ElementType = "div">(
    { flex, right, full, gap, ...props }: BoxProps<As>,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    return (
      <div
        {...props}
        className={twMerge(styles({ flex, right, full, gap }), props.className)}
        ref={ref}
      />
    );
  },
) as BoxComponent;
