import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { useState } from "react";

import { debouncedLogout } from "@/features/auth/api";
import { getApiUrl } from "@/lib/api";
import { useConfig } from "@/lib/config";
import {
  type TRPCClientErrorRouter,
  createTRPCQueryUtils,
  httpBatchLink,
  httpLink,
  loggerLink,
  splitLink,
  superjson,
  trpc,
} from "@blis/trpc/react";
import { toast } from "@blis/ui";

const url = getApiUrl("/api/trpc");

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError(_err) {
      const err = _err as never as TRPCClientErrorRouter;
      const code = err.data?.code;
      if (code === "UNAUTHORIZED") {
        debouncedLogout(true);
      } else if (code === "FORBIDDEN") {
        toast.error({
          title: "Forbidden",
          description: "You do not have permission to perform that operation.",
        });
      }
    },
  }),
  defaultOptions: {
    queries: {
      staleTime: 1000,
      retry(failureCount, _err) {
        const err = _err as never as TRPCClientErrorRouter;
        const code = err.data?.code;
        if (
          code === "BAD_REQUEST" ||
          code === "FORBIDDEN" ||
          code === "UNAUTHORIZED" ||
          code === "NOT_FOUND"
        ) {
          return false;
        }
        return failureCount < 3;
      },
    },
  },
});

const trpcClient = trpc.createClient({
  // transformer: superjson,
  links: [
    loggerLink({
      enabled() {
        return import.meta.env.DEV;
      },
    }),
    splitLink({
      // check for context property `skipBatch`
      condition: (op) => {
        return op.context.skipBatch === true;
      },
      true: httpLink({ url, fetch: credentialedFetch, transformer: superjson }),
      false: httpBatchLink({
        url,
        fetch: credentialedFetch,
        transformer: superjson,
      }),
    }),
  ],
});

export function QueryProvider({ children }: { children: React.ReactNode }) {
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </trpc.Provider>
  );
}

export const apiUtils = createTRPCQueryUtils({
  queryClient,
  client: trpcClient,
});

function credentialedFetch(
  url: URL | string | RequestInfo,
  options?: RequestInit,
) {
  return fetch(url, {
    ...options,
    credentials: "include",
  });
}
