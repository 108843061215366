import { atom, useAtom } from "jotai";
import { useEffect, useRef, useState } from "react";
import { useInterval } from "react-use";

import { Button, Dialog, Text } from "@blis/ui";

import { logout } from "../api";

const timeoutWarningAtom = atom(false);

export function useSetTimeoutWarning() {
  return useAtom(timeoutWarningAtom);
}

function Countdown() {
  const [count, setCount] = useState(60);

  useInterval(
    () => {
      setCount((c) => c - 1);
    },
    count <= 0 ? null : 1000,
  );

  useEffect(() => {
    if (count <= 0) {
      logout(true);
    }
  }, [count]);

  return (
    <Text as="p">
      You will be automatically signed out after {count} seconds.
    </Text>
  );
}

export function TimeoutWarningDialog() {
  const titleRef = useRef<string>(document.title);
  const [open, setOpen] = useAtom(timeoutWarningAtom);

  useEffect(() => {
    if (open) {
      titleRef.current = document.title;
      document.title = "Still there?";
    } else {
      document.title = titleRef.current;
    }
  }, [open]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <Dialog.Content>
        <Dialog.Title>Do you want to stay signed in?</Dialog.Title>
        <Dialog.Body>
          <Countdown />
        </Dialog.Body>
        <Dialog.Footer>
          <Dialog.Close asChild>
            <Button color="primary">Yes</Button>
          </Dialog.Close>
          <Button ghost onClick={() => logout()}>
            No
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  );
}
