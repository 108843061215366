import * as React from "react";
import { ErrorBoundary } from "react-error-boundary";

import { LoadingPage } from "@/components/LoadingPage";
import { Button, Flex, Text, Tooltip, useTinyKeys } from "@blis/ui";

import { QueryProvider } from "./query";

function ErrorFallback() {
  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      gap="4"
      className="w-screen h-screen"
      role="alert"
    >
      <Text size="4">Ooops, something went wrong :( </Text>
      <Button onClick={() => window.location.assign(window.location.origin)}>
        Refresh
      </Button>
    </Flex>
  );
}

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  useTinyKeys(
    {
      Escape: (evt) => {
        (evt.target as HTMLElement).blur();
      },
    },
    [],
    false,
  );

  return (
    <React.Suspense fallback={<LoadingPage />}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <QueryProvider>
          <Tooltip.Provider delayDuration={500}>{children}</Tooltip.Provider>
        </QueryProvider>
      </ErrorBoundary>
    </React.Suspense>
  );
};
