import { useSnapshot } from "valtio";
import { z } from "zod";

import { persistedProxy } from "@/utils/persistedState";

export const profileState = persistedProxy(
  { enableDashboard: false },
  "blis.user.profile",
  z.object({
    enableDashboard: z.boolean(),
  }),
);

export function useProfileSettings() {
  const state = useSnapshot(profileState);
  return {
    state,
    toggleDashboard: () => {
      profileState.enableDashboard = !profileState.enableDashboard;
    },
  };
}
