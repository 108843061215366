import { type Ref, type SVGProps, forwardRef, memo } from "react";
import { withStyles } from "./utils/styles";

const StatusNewIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    height={24}
    width={24}
    ref={ref}
    {...props}
  >
    <g>
      <path
        d="M21.34 8.44a1 1 0 0 0 1.3 0.58 1 1 0 0 0 0.57 -1.29 12 12 0 0 0 -0.88 -1.84A1 1 0 0 0 21 5.53a1 1 0 0 0 -0.35 1.37 10.84 10.84 0 0 1 0.69 1.54Z"
        fill="currentcolor"
        strokeWidth={1}
      />
      <path
        d="M19.58 2.69a12 12 0 0 0 -1.69 -1.15 1 1 0 0 0 -1.36 0.38 1 1 0 0 0 0.38 1.36 10.21 10.21 0 0 1 1.4 1 1 1 0 1 0 1.27 -1.55Z"
        fill="currentcolor"
        strokeWidth={1}
      />
      <path
        d="M11.88 2a9.66 9.66 0 0 1 1.7 0.12 1 1 0 0 0 1.15 -0.82 1 1 0 0 0 -0.82 -1.15 11.61 11.61 0 0 0 -2 -0.15 1 1 0 0 0 0 2Z"
        fill="currentcolor"
        strokeWidth={1}
      />
      <path
        d="M6.91 3.39a10.28 10.28 0 0 1 1.53 -0.74A1 1 0 0 0 9 1.36 1 1 0 0 0 7.73 0.78a13 13 0 0 0 -1.84 0.89 1 1 0 1 0 1 1.72Z"
        fill="currentcolor"
        strokeWidth={1}
      />
      <path
        d="M1.92 7.47a1 1 0 0 0 1.37 -0.38 9.57 9.57 0 0 1 1 -1.4 1 1 0 0 0 -1.6 -1.27 12.9 12.9 0 0 0 -1.15 1.69 1 1 0 0 0 0.38 1.36Z"
        fill="currentcolor"
        strokeWidth={1}
      />
      <path
        d="M1 13.12a1 1 0 0 0 1 -1 9.66 9.66 0 0 1 0.13 -1.7 1 1 0 0 0 -0.83 -1.16 1 1 0 0 0 -1.15 0.83 11.61 11.61 0 0 0 -0.15 2 1 1 0 0 0 1 1Z"
        fill="currentcolor"
        strokeWidth={1}
      />
      <path
        d="M2.66 15.56a1 1 0 1 0 -1.87 0.71 12.43 12.43 0 0 0 0.88 1.84 1 1 0 0 0 1.33 0.35 1 1 0 0 0 0.35 -1.37 10.73 10.73 0 0 1 -0.69 -1.53Z"
        fill="currentcolor"
        strokeWidth={1}
      />
      <path
        d="M7.09 20.71a10.21 10.21 0 0 1 -1.4 -1 1 1 0 0 0 -1.27 1.59 12 12 0 0 0 1.69 1.15 1 1 0 0 0 1 -1.74Z"
        fill="currentcolor"
        strokeWidth={1}
      />
      <path
        d="M12.12 22a9.66 9.66 0 0 1 -1.7 -0.13 1 1 0 0 0 -1.15 0.83 1 1 0 0 0 0.83 1.15A12.2 12.2 0 0 0 12 24a1 1 0 0 0 1.12 -1 1 1 0 0 0 -1 -1Z"
        fill="currentcolor"
        strokeWidth={1}
      />
      <path
        d="M17.09 20.6a9.39 9.39 0 0 1 -1.53 0.74 1 1 0 0 0 -0.56 1.29 1 1 0 0 0 1.29 0.58 11.15 11.15 0 0 0 1.84 -0.89 1 1 0 0 0 0.33 -1.32 1 1 0 0 0 -1.37 -0.4Z"
        fill="currentcolor"
        strokeWidth={1}
      />
      <path
        d="M22.08 16.52a1 1 0 0 0 -1.37 0.38 9.67 9.67 0 0 1 -0.95 1.41 1 1 0 1 0 1.55 1.26 11.69 11.69 0 0 0 1.15 -1.69 1 1 0 0 0 -0.38 -1.36Z"
        fill="currentcolor"
        strokeWidth={1}
      />
      <path
        d="M23 10.88a1 1 0 0 0 -1 1 9.66 9.66 0 0 1 -0.13 1.7 1 1 0 0 0 2 0.32 11.68 11.68 0 0 0 0.15 -2A1 1 0 0 0 23 10.88Z"
        fill="currentcolor"
        strokeWidth={1}
      />
    </g>
  </svg>
);
const ForwardRef = forwardRef(StatusNewIcon);
const Memo = withStyles(memo(ForwardRef));
export { Memo as StatusNewIcon };
