import { ChevronDownIcon, XIcon } from "lucide-react";
import React, { type Ref } from "react";
import ReactSelect, {
  type Props as ReactSelectProps,
  type OptionProps,
  type GroupBase,
  type DropdownIndicatorProps,
  components,
  type ClearIndicatorProps,
  type MultiValueRemoveProps,
} from "react-select";
import ReactAsyncCreatableSelect, {
  type AsyncCreatableProps as ReactAsyncCreatableProps,
} from "react-select/async-creatable";
import type { default as ReactSelectType } from "react-select/base";
import ReactCreatableSelect, {
  type CreatableProps as ReactCreatableSelectProps,
} from "react-select/creatable";

import { InputWrapper, type InputWrapperBaseProps } from "./InputWrapper";

export type SelectProps<
  T = unknown,
  M extends boolean = false,
  G extends GroupBase<T> = GroupBase<T>,
> = InputWrapperBaseProps & ReactSelectProps<T, M, G>;

// biome-ignore lint/suspicious/noExplicitAny: it's ok
const DropdownIndicator = (props: DropdownIndicatorProps<any, any, any>) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDownIcon className="size-4" />
    </components.DropdownIndicator>
  );
};

// biome-ignore lint/suspicious/noExplicitAny: it's ok
const ClearIndicator = (props: ClearIndicatorProps<any, any, any>) => {
  return (
    <components.ClearIndicator {...props}>
      <XIcon className="size-4" />
    </components.ClearIndicator>
  );
};

// biome-ignore lint/suspicious/noExplicitAny: it's ok
const MultiValueRemove = (props: MultiValueRemoveProps<any, any, any>) => {
  return (
    <components.MultiValueRemove {...props}>
      <XIcon style={{ height: 8, width: 8 }} />
    </components.MultiValueRemove>
  );
};

const onBlurWorkaround = (event: React.FocusEvent<HTMLInputElement>) => {
  const element = event.relatedTarget as HTMLElement;
  if (element?.getAttribute("role") === "dialog") return;
  element?.focus();
};

function Select<
  T = unknown,
  M extends boolean = false,
  G extends GroupBase<T> = GroupBase<T>,
>(
  { label, helpText, ...props }: SelectProps<T, M, G>,
  ref: React.Ref<ReactSelectType<T, M, G>>,
) {
  return (
    <InputWrapper
      label={label}
      id={props.inputId}
      className="w-full"
      error={props.error}
      helpText={helpText}
    >
      <ReactSelect
        classNamePrefix="brs"
        {...props}
        onBlur={(e) => {
          onBlurWorkaround(e);
          props.onBlur?.(e);
        }}
        ref={ref}
        components={{
          ...props.components,
          DropdownIndicator,
          ClearIndicator,
          MultiValueRemove,
        }}
      />
    </InputWrapper>
  );
}

export type CreatableSelectProps<
  T = unknown,
  M extends boolean = false,
  G extends GroupBase<T> = GroupBase<T>,
> = InputWrapperBaseProps & ReactCreatableSelectProps<T, M, G>;

function CreatableSelect<
  T = unknown,
  M extends boolean = false,
  G extends GroupBase<T> = GroupBase<T>,
>(
  { label, ...props }: CreatableSelectProps<T, M, G>,
  ref: React.Ref<ReactSelectType<T, M, G>>,
) {
  return (
    <InputWrapper
      label={label}
      id={props.inputId}
      className="w-full"
      error={props.error}
    >
      <ReactCreatableSelect
        classNamePrefix="brs"
        {...props}
        onBlur={(e) => {
          onBlurWorkaround(e);
          props.onBlur?.(e);
        }}
        ref={ref}
        components={{
          ...props.components,
          DropdownIndicator,
          ClearIndicator,
          MultiValueRemove,
        }}
      />
    </InputWrapper>
  );
}

const _Select = React.forwardRef(Select) as <
  T = unknown,
  M extends boolean = false,
  G extends GroupBase<T> = GroupBase<T>,
>(
  props: SelectProps<T, M, G> & { ref?: Ref<ReactSelectType<T, M, G>> },
) => React.ReactElement;

const _CreatableSelect = React.forwardRef(CreatableSelect) as <
  T = unknown,
  M extends boolean = false,
  G extends GroupBase<T> = GroupBase<T>,
>(
  props: CreatableSelectProps<T, M, G> & {
    ref?: Ref<ReactSelectType<T, M, G>>;
  },
) => React.ReactElement;

export { _Select as Select, _CreatableSelect as CreatableSelect };

export { components as selectComponents } from "react-select";
export type { OptionProps };

export type AsyncCreatableSelectProps<
  T = unknown,
  M extends boolean = false,
  G extends GroupBase<T> = GroupBase<T>,
> = InputWrapperBaseProps & ReactAsyncCreatableProps<T, M, G>;

function AsyncCreatableSelect<
  T = unknown,
  M extends boolean = false,
  G extends GroupBase<T> = GroupBase<T>,
>(
  { label, ...props }: AsyncCreatableSelectProps<T, M, G>,
  ref: React.Ref<ReactSelectType<T, M, G>>,
) {
  return (
    <InputWrapper
      label={label}
      id={props.inputId}
      className="w-full"
      error={props.error}
    >
      <ReactAsyncCreatableSelect
        classNamePrefix="brs"
        {...props}
        onBlur={(e) => {
          onBlurWorkaround(e);
          props.onBlur?.(e);
        }}
        ref={ref}
        components={{
          ...props.components,
          DropdownIndicator,
          ClearIndicator,
          MultiValueRemove,
        }}
      />
    </InputWrapper>
  );
}

const _AsyncCreatableSelect = React.forwardRef(AsyncCreatableSelect) as <
  T = unknown,
  M extends boolean = false,
  G extends GroupBase<T> = GroupBase<T>,
>(
  props: AsyncCreatableSelectProps<T, M, G> & {
    ref?: Ref<ReactSelectType<T, M, G>>;
  },
) => React.ReactElement;

export { _AsyncCreatableSelect as AsyncCreatableSelect };
