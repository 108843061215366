import { type VariantProps, cva } from "class-variance-authority";
import { twMerge } from "tailwind-merge";

const styles = cva("animate-pulse rounded-md bg-muted", {
  variants: {
    variant: {
      avatar1: "rounded-full h-4 w-4",
      avatar2: "rounded-full h-5 w-5",
      avatar3: "rounded-full h-6 w-6",
      avatar4: "rounded-full h-7 w-7",
      avatar5: "rounded-full h-8 w-8",
      avatar6: "rounded-full h-9 w-9",
      text: "h-4",
      title: "h-5",
      heading: "h-4",
      button: "rounded h-5 w-8",
    },
  },
  defaultVariants: {
    variant: "text",
  },
});

function Skeleton({
  className,
  variant,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof styles>) {
  return <div className={twMerge(styles({ variant }), className)} {...props} />;
}

export { Skeleton };
