import { mapCursorToMax } from "map-cursor-to-max";
import { useCallback, useEffect, useMemo, useState } from "react";
import { type KeyBindingMap, tinykeys } from "tinykeys";
import { useSnapshot } from "valtio";

import { keyNav } from "../store";

const isInput = (target: EventTarget | null) => {
  return (
    target &&
    /INPUT|TEXTAREA/.test((target as HTMLElement).tagName.toUpperCase())
  );
};
export type UseMDKeyNavProps<T> = {
  list: T[][];
  enabled?: boolean;
  onSelect: (item?: T) => void;
  initial?: T | null;
  selected?: T | null;
};

export type UseKeyNavProps<T> = Omit<UseMDKeyNavProps<T>, "list"> & {
  list: T[];
};

export function useKeyNav<T>({ list, ...props }: UseKeyNavProps<T>) {
  const newList = useMemo(() => [list], [list]);
  return useMDKeyNav({
    list: newList,
    ...props,
  });
}

export function useMDKeyNav<T>({
  list,
  onSelect,
  enabled = true,
  initial = null,
  selected = null,
}: UseMDKeyNavProps<T>) {
  const values = useMemo(
    () => findInitialValues(list, initial),
    [list, initial],
  );
  const [column, setColumn] = useState<number>(() => (values ? values[0] : 0));
  const [index, setIndex] = useState<number | null>(() =>
    values ? values[1] : null,
  );
  const snap = useSnapshot(keyNav);

  useEffect(() => {
    if (selected) {
      const values = findInitialValues(list, selected);
      if (values) {
        setColumn(values[0]);
        setIndex(values[1]);
      }
    }
  }, [list, selected]);

  const keyCallback = useCallback(
    (callback: (evt: KeyboardEvent) => void) => {
      return (event: KeyboardEvent) => {
        if (isInput(event.target) || !snap.enabled || !enabled) {
          return;
        }
        event.preventDefault();
        event.stopPropagation();
        callback(event);
      };
    },
    [enabled, snap],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: only run once
  useEffect(() => {
    if (values && index !== null) {
      const initialSelection = list[column][index];
      onSelect(initialSelection);
    }
  }, []);

  const prev = useCallback(() => {
    if (!list[column]) {
      return;
    }
    const newIndex =
      index === null
        ? list.length - 1
        : mapCursorToMax(index - 1, list[column].length);
    setIndex(newIndex);
    onSelect(list[column][newIndex]);
  }, [column, index, list, onSelect]);

  const next = useCallback(() => {
    if (!list[column]) {
      return;
    }
    const newIndex =
      index === null ? 0 : mapCursorToMax(index + 1, list[column].length);
    setIndex(newIndex);
    onSelect(list[column][newIndex]);
  }, [column, index, list, onSelect]);

  const prevCol = useCallback(() => {
    let newCol = column - 1;
    // skip over undefined columns
    for (; newCol > column - list.length - 1; newCol--) {
      newCol = mapCursorToMax(newCol, list.length);
      if (list[newCol]) {
        break;
      }
    }
    if (!list[newCol]) {
      return;
    }
    const newIndex = Math.min(index ?? 0, list[newCol].length - 1);
    setColumn(newCol);
    setIndex(newIndex);
    onSelect(list[newCol][newIndex ?? 0]);
  }, [column, index, list, onSelect]);

  const nextCol = useCallback(() => {
    let newCol = column + 1;
    // skip over undefined columns
    for (; newCol < list.length + 1; newCol++) {
      newCol = mapCursorToMax(newCol, list.length);
      if (list[newCol]) {
        break;
      }
    }
    if (!list[newCol]) {
      return;
    }
    const newIndex = Math.min(index ?? 0, list[newCol].length - 1);
    setColumn(newCol);
    setIndex(newIndex);
    onSelect(list[newCol][newIndex ?? 0]);
  }, [column, index, list, onSelect]);

  const firstItem = useCallback(() => {
    const newIndex = 0;
    setIndex(newIndex);
    onSelect(list[column][newIndex]);
  }, [column, list, onSelect]);

  const lastItem = useCallback(() => {
    const newIndex = list[column] ? list[column].length - 1 : 0;
    setIndex(newIndex);
    onSelect(list[column][newIndex]);
  }, [column, list, onSelect]);

  const map: KeyBindingMap = useMemo(
    () => ({
      ArrowLeft: keyCallback(prevCol),
      ArrowUp: keyCallback(prev),
      ArrowDown: keyCallback(next),
      ArrowRight: keyCallback(nextCol),
      H: keyCallback(prevCol),
      K: keyCallback(prev),
      J: keyCallback(next),
      L: keyCallback(nextCol),
      Home: keyCallback(firstItem),
      End: keyCallback(lastItem),
    }),
    [firstItem, keyCallback, lastItem, next, nextCol, prev, prevCol],
  );

  useEffect(() => {
    const unsub = tinykeys(window, map);
    return () => unsub();
  }, [map]);

  return { setIndex };
}

function findInitialValues<T>(
  list: T[][],
  initial: T | null,
): [number, number] | null {
  if (!initial || !list.length) {
    return null;
  }
  for (let i = 0; i < list.length; i++) {
    for (let j = 0; j < list[i]?.length; j++) {
      if (list[i][j] === initial) {
        return [i, j];
      }
    }
  }
  return null;
}
