import { type VariantProps, cva } from "class-variance-authority";
import * as React from "react";
import { twMerge } from "tailwind-merge";

const responsiveStyles = cva("", {
  variants: {
    hideOn: {
      sm: "hidden sm:table-cell",
      md: "hidden md:table-cell",
      lg: "hidden lg:table-cell",
      xl: "hidden xl:table-cell",
    },
  },
});

const alignStyles = cva("", {
  variants: {
    align: {
      left: "text-left",
      center: "text-center",
      right: "text-end",
    },
  },
});

const Table = React.forwardRef<
  HTMLTableElement,
  React.HTMLAttributes<HTMLTableElement> & { striped?: boolean }
>(({ className, striped, ...props }, ref) => (
  <div className="w-full overflow-auto">
    <table
      ref={ref}
      className={twMerge(
        "w-full caption-bottom text-sm",
        striped ? "[&>tbody>tr:nth-child(odd)]:bg-muted/50" : "",
        className,
      )}
      {...props}
    />
  </div>
));
Table.displayName = "Table";

const TableHeader = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <thead
    ref={ref}
    className={twMerge("[&_tr]:border-b", className)}
    {...props}
  />
));
TableHeader.displayName = "TableHeader";

const TableBody = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tbody
    ref={ref}
    className={twMerge("[&_tr:last-child]:border-0", className)}
    {...props}
  />
));
TableBody.displayName = "TableBody";

const TableFooter = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tfoot
    ref={ref}
    className={twMerge(
      "bg-primary font-medium text-primary-foreground",
      className,
    )}
    {...props}
  />
));
TableFooter.displayName = "TableFooter";

const TableRow = React.forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement>
>(({ className, ...props }, ref) => (
  <tr
    ref={ref}
    className={twMerge(
      "border-b data-[state=selected]:bg-muted",
      // "hover:bg-muted/50",
      className,
    )}
    {...props}
  />
));
TableRow.displayName = "TableRow";

const TableHead = React.forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement> &
    VariantProps<typeof responsiveStyles> &
    VariantProps<typeof alignStyles>
>(({ className, hideOn, align, ...props }, ref) => (
  <th
    ref={ref}
    className={twMerge(
      "h-12 px-3 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0",
      responsiveStyles({ hideOn }),
      alignStyles({ align }),
      className,
    )}
    {...props}
  />
));
TableHead.displayName = "TableHead";

const TableCell = React.forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement> &
    VariantProps<typeof responsiveStyles>
>(({ className, hideOn, ...props }, ref) => (
  <td
    ref={ref}
    className={twMerge(
      "p-3 align-middle [&:has([role=checkbox])]:pr-0",
      responsiveStyles({ hideOn }),
      className,
    )}
    {...props}
  />
));
TableCell.displayName = "TableCell";

const TableCaption = React.forwardRef<
  HTMLTableCaptionElement,
  React.HTMLAttributes<HTMLTableCaptionElement>
>(({ className, ...props }, ref) => (
  <caption
    ref={ref}
    className={twMerge("mt-4 text-sm text-muted-foreground", className)}
    {...props}
  />
));
TableCaption.displayName = "TableCaption";

export {
  Table,
  TableHeader as Thead,
  TableBody as Tbody,
  TableFooter as Tfoot,
  TableHead as Th,
  TableRow as Tr,
  TableCell as Td,
  TableCaption,
};
