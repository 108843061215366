import * as SwitchPrimitives from "@radix-ui/react-switch";
import { type VariantProps, cva } from "class-variance-authority";
import * as React from "react";
import { twMerge } from "tailwind-merge";

const DEFAULT_SIZE = "sm";

const rootStyles = cva("", {
  variants: {
    size: {
      sm: "h-[16px] w-[28px]",
      lg: "h-[24px] w-[44px]",
    },
  },
  defaultVariants: {
    size: DEFAULT_SIZE,
  },
});

const thumbStyles = cva("", {
  variants: {
    size: {
      sm: "h-3 w-3 data-[state=checked]:translate-x-3",
      lg: "h-5 w-5 data-[state=checked]:translate-x-5",
    },
  },
  defaultVariants: {
    size: DEFAULT_SIZE,
  },
});

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> &
    VariantProps<typeof rootStyles>
>(({ className, size, ...props }, ref) => (
  <SwitchPrimitives.Root
    className={twMerge(
      "peer inline-flex shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=unchecked]:bg-input",
      rootStyles({ size }),
      className,
    )}
    {...props}
    ref={ref}
  >
    <SwitchPrimitives.Thumb
      className={twMerge(
        "pointer-events-none block  rounded-full bg-background shadow-lg ring-0 transition-transform data-[state=unchecked]:translate-x-0",
        thumbStyles({ size }),
      )}
    />
  </SwitchPrimitives.Root>
));
Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
