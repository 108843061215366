import dayjs from "dayjs";
import { type KeyboardEventHandler, useState } from "react";
import { useEffect } from "react";
import {
  type ChangeEventHandler,
  type ComponentPropsWithoutRef,
  forwardRef,
} from "react";

import { setNativeInputValue, useEnsureRef } from "../utils";

import { Input } from "./Input";
import { InputWrapper, type InputWrapperBaseProps } from "./InputWrapper";

export type DateTimePickerProps = InputWrapperBaseProps &
  ComponentPropsWithoutRef<typeof Input> & {
    time?: boolean;
    customKeys?: Record<string, () => string>;
  };

export const DateTimePicker = forwardRef<HTMLInputElement, DateTimePickerProps>(
  ({ time, label, customKeys, ...props }, ref) => {
    const type = time ? "datetime-local" : "date";
    const innerRef = useEnsureRef(ref);

    const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (evt) => {
      const format = type === "date" ? "YYYY-MM-DD" : "YYYY-MM-DDTHH:mm";
      if (evt.key === "t") {
        setNativeInputValue(innerRef.current, dayjs().format(format));
      } else if (evt.key === "y") {
        setNativeInputValue(
          innerRef.current,
          dayjs().subtract(1, "day").format(format),
        );
      }
      for (const [key, fn] of Object.entries(customKeys || {})) {
        if (evt.key === key) {
          setNativeInputValue(innerRef.current, fn());
        }
      }

      props.onKeyDown?.(evt);
    };

    return (
      <InputWrapper label={label} id={props.id} error={props.error}>
        <Input
          type={type}
          {...props}
          ref={innerRef}
          onKeyDown={onKeyDown}
          max="9999-12-31T23:59"
        />
      </InputWrapper>
    );
  },
);
