import { Slot, Slottable } from "@radix-ui/react-slot";
import { type VariantProps, cva } from "class-variance-authority";
import * as React from "react";
import { twMerge } from "tailwind-merge";

import { Spinner } from "./Spinner";

const buttonVariants = cva(
  [
    "inline-flex items-center justify-center text-sm font-medium transition-colors ring-offset-background whitespace-nowrap gap-2",
    "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none",
    "[&>svg:first-child]:hidden",
  ],

  {
    variants: {
      color: {
        primary: "bg-primary text-primary-foreground hover:bg-primary/90",
        red: "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        gray: "bg-transparent border border-input text-secondary-foreground hover:bg-secondary/80",
        link: "underline-offset-4 hover:underline text-primary",
      },
      size: {
        xs: "h-5 px-1 text-[10px] rounded-sm",
        sm: "h-6 px-2 rounded text-xs [&>svg]:size-3.5",
        md: "h-8 py-1 px-3 rounded [&>svg]:size-4",
        lg: "h-10 px-8 rounded-md",
        "icon-xs": "size-5 [&>svg]:size-3 rounded-sm flex-shrink-0",
        "icon-sm": "size-6 [&>svg]:size-3.5 rounded flex-shrink-0",
        icon: "size-8 [&>svg]:size-4 rounded flex-shrink-0",
      },
      ghost: {
        true: ["bg-transparent bodrer-none"],
      },
      outline: {
        true: ["bg-transparent border"],
        dashed: ["bg-transparent border border-dashed"],
      },
      loading: {
        true: "[&>svg:first-child]:inline [&>svg:nth-child(2)]:hidden",
      },
    },
    compoundVariants: [
      {
        ghost: true,
        color: ["gray", "secondary"],
        className: "border-none",
      },
      {
        ghost: true,
        color: ["red"],
        className: "text-destructive hover:bg-destructive/10",
      },
    ],
    defaultVariants: {
      color: "gray",
      size: "md",
    },
  },
);

export interface ButtonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "color">,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      color,
      size,
      ghost,
      outline,
      loading,
      asChild = false,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={twMerge(
          buttonVariants({
            color,
            size,
            ghost,
            outline,
            loading,
            className,
          }),
        )}
        ref={ref}
        {...props}
      >
        <Spinner />
        <Slottable>{children}</Slottable>
      </Comp>
    );
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };
