import { type Ref, type SVGProps, forwardRef, memo } from "react";
import { withStyles } from "./utils/styles";

const StatusCancelledIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    height={24}
    width={24}
    ref={ref}
    {...props}
  >
    <path
      d="M12 24A12 12 0 1 0 0 12a12 12 0 0 0 12 12ZM7.05 8.46a1 1 0 0 1 1.41 -1.41l3.36 3.36a0.25 0.25 0 0 0 0.36 0l3.36 -3.36A1 1 0 0 1 17 8.46l-3.36 3.36a0.25 0.25 0 0 0 0 0.36L17 15.54A1 1 0 0 1 17 17a1 1 0 0 1 -0.71 0.29 1 1 0 0 1 -0.7 -0.29l-3.36 -3.36a0.25 0.25 0 0 0 -0.36 0L8.46 17a1 1 0 0 1 -0.7 0.29 1 1 0 0 1 -0.71 -0.29 1 1 0 0 1 0 -1.41l3.36 -3.36a0.25 0.25 0 0 0 0 -0.36Z"
      fill="currentcolor"
      strokeWidth={1}
    />
  </svg>
);

const ForwardRef = forwardRef(StatusCancelledIcon);
const Memo = withStyles(memo(ForwardRef));
export { Memo as StatusCancelledIcon };
