import * as Collapsible from "@radix-ui/react-collapsible";
import { ChevronDownIcon, ChevronRightIcon } from "lucide-react";
import { forwardRef, useEffect, useState } from "react";

import { Button } from "../../base/Button";

import { ListItem, type ListItemProps } from "./ListItem";

export const CollapsibleListItem = forwardRef<
  HTMLDivElement,
  ListItemProps & { collapse: React.ReactNode; expanded?: boolean }
>(({ children, collapse, expanded, ...props }, ref) => {
  const [open, setOpen] = useState(expanded);
  useEffect(() => {
    setOpen(expanded);
  }, [expanded]);
  return (
    <Collapsible.Root open={open} onOpenChange={setOpen}>
      <Collapsible.Trigger asChild>
        <ListItem {...props} ref={ref}>
          <Button
            ghost
            color="gray"
            className="ml-3 rounded h-4 w-4 px-1"
            asChild
          >
            {open ? <ChevronDownIcon /> : <ChevronRightIcon />}
          </Button>
          {children}
        </ListItem>
      </Collapsible.Trigger>
      <div className="ml-[27px]">{collapse}</div>
    </Collapsible.Root>
  );
});

export { Collapsible };
