import { Link, useSearch } from "@tanstack/react-router";
import { AlertCircleIcon } from "lucide-react";

import { Button, Centered, Flex, Text } from "@blis/ui";

export function ErrorPage() {
  const params = useSearch({ strict: false }) as { message?: string };
  const message = params.message || "An unknown error occurred";

  return (
    <Centered>
      <Flex
        direction="column"
        justify="center"
        align="center"
        gap="2"
        className="border p-5 rounded-md bg-muted"
      >
        <AlertCircleIcon className="size-7 text-red-600" />
        <Text size="4">{message}</Text>
        <Button asChild>
          <Link to="/login" replace>
            Back to login
          </Link>
        </Button>
      </Flex>
    </Centered>
  );
}
