import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import * as React from "react";

import { cn } from "../utils";
import { InputWrapper, type InputWrapperBaseProps } from "./InputWrapper";
import { Label } from "./Label";

const RadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root> &
    InputWrapperBaseProps
>(({ className, orientation, label, error, ...props }, ref) => {
  orientation = orientation || "horizontal";
  return (
    <InputWrapper label={label} error={error}>
      <RadioGroupPrimitive.Root
        className={cn(
          {
            "gap-3 flex flex-row": orientation === "horizontal",
            "gap-2 flex flex-col": orientation === "vertical",
          },
          className,
        )}
        {...props}
        orientation={orientation}
        ref={ref}
      />
    </InputWrapper>
  );
});
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName;

const RadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>
>(({ className, children, ...props }, ref) => {
  return (
    <div className="flex gap-1 items-center">
      <RadioGroupPrimitive.Item
        ref={ref}
        className={cn(
          "aspect-square h-4 w-4 rounded-full border border-primary text-primary peer ring-offset-background focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
          className,
        )}
        {...props}
      >
        <RadioGroupPrimitive.Indicator className="flex items-center justify-center">
          <div className="w-2.5 h-2.5 bg-primary rounded-full" />
        </RadioGroupPrimitive.Indicator>
      </RadioGroupPrimitive.Item>
      <Label htmlFor={props.id} className="cursor-default font-normal">
        {children}
      </Label>
    </div>
  );
});
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName;

export { RadioGroup, RadioGroupItem as Radio };
