import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useRef, useState } from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";

import type { TAuthProvider } from "@/lib/types";
import { trpc } from "@blis/trpc/react";
import { Button, Flex, Text, TextInput } from "@blis/ui";

import { AuthLayout } from "./AuthLayout";

const schema = z.object({
  email: z.string().email("Email address required"),
});

const passwordSchema = schema.extend({
  password: z.string(),
});

type FormValues = z.infer<typeof passwordSchema>;

const loginPath = "/api/auth/login";

export function Login({ next }: { next?: string }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>("");
  const [authProvider, setAuthProvider] = useState<TAuthProvider | null>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const utils = trpc.useUtils();

  // const { data, isFetching } = trpc.anon.authProvider.useQuery(email, {
  //   enabled: !!email,
  // });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(
      authProvider?.type === "password" ? passwordSchema : schema,
    ),
  });

  const onLogin: SubmitHandler<FormValues> = useCallback(
    async (values) => {
      try {
        setError("");
        setLoading(true);
        let providerType = authProvider?.type;
        if (!providerType) {
          const provider = await utils.anon.authProvider.fetch(values.email, {
            retry: 1,
          });
          providerType = provider.type;
          setAuthProvider(provider);

          if (providerType === "password") {
            setLoading(false);
            // return early to capture password
            return;
          }
        }

        if (providerType === "password") {
          formRef.current?.submit();
        } else if (providerType === "sso") {
          const returnTo = `${window.location.origin}${next || "/"}`;
          const url = new URL(loginPath, window.location.origin);
          url.searchParams.append("returnTo", returnTo);
          url.searchParams.append("email", values.email);
          window.location.href = url.toString();
        } else {
          setLoading(false);
          setError(
            "We're unable to sign you in. Please contact your administrator for assistance.",
          );
        }
      } catch (err) {
        setLoading(false);
        setError("Something went wrong. Please try again.");
        console.error(err);
      }
    },
    [authProvider?.type, utils.anon.authProvider, next],
  );

  return (
    <AuthLayout title="Sign in to bLIS">
      <Text color="gray">Enter your email below to continue</Text>
      <Flex direction="column" className="w-full mt-6">
        {error && (
          <Text color="destructive" size="2" className="mb-2">
            {error}
          </Text>
        )}
        <form
          onSubmit={handleSubmit(onLogin)}
          ref={formRef}
          action="/api/auth/login"
          method="post"
        >
          <Flex direction="column" gap="2">
            <TextInput
              {...register("email", {
                onBlur: async (evt) => {
                  const email = evt.target.value;
                  if (authProvider && email) {
                    // reset auth provider if email changes
                    const provider = await utils.anon.authProvider.fetch(
                      evt.target.value,
                      {
                        retry: false,
                      },
                    );
                    setAuthProvider(provider);
                  }
                },
              })}
              placeholder="Email address"
              type="email"
              error={errors.email?.message}
            />
            {authProvider?.type === "password" && (
              <TextInput
                {...register("password")}
                autoFocus
                placeholder="Password"
                type="password"
                error={errors.password?.message}
              />
            )}
            <Button type="submit" color="primary" loading={loading}>
              Continue
            </Button>
          </Flex>
        </form>
      </Flex>
    </AuthLayout>
  );
}
