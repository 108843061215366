import { type Ref, type SVGProps, forwardRef, memo } from "react";
import { withStyles } from "./utils/styles";

const StatusInProgressIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    height={24}
    width={24}
    ref={ref}
    {...props}
  >
    <g>
      <path
        d="M12 0a12 12 0 1 0 12 12A12 12 0 0 0 12 0Zm0 22a10 10 0 1 1 10 -10 10 10 0 0 1 -10 10Z"
        fill="currentcolor"
        strokeWidth={1}
      />
      <path
        d="M12 4a0.5 0.5 0 0 0 -0.5 0.5v15a0.5 0.5 0 0 0 0.5 0.5 8 8 0 0 0 0 -16Z"
        fill="currentcolor"
        strokeWidth={1}
      />
    </g>
  </svg>
);

const ForwardRef = forwardRef(StatusInProgressIcon);
const Memo = withStyles(memo(ForwardRef));
export { Memo as StatusInProgressIcon };
