import { useEffect, useState } from "react";

import { Centered, Spinner } from "@blis/ui";

export function LoadingPage() {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const id = setTimeout(() => setShow(true), 100);
    return () => clearTimeout(id);
  }, []);

  return (
    <Centered>
      {show ? <Spinner className="text-primary h-8 w-8" /> : null}
    </Centered>
  );
}
